import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueNativeSock from "vue-native-websocket";
import vueDebounce from "vue-debounce";

Vue.use(Vuetify);
Vue.use(VueNativeSock, process.env.VUE_APP_WS, {
  format: "json",
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
});
Vue.use(vueDebounce);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#dbd8e3",
        secondary: "#5c5470",
        accent: "#352f44",
      },
    },
  },
});
