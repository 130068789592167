<template>
  <v-app>
    <v-navigation-drawer app v-model="showDrawer">
      <MainNav
        :collections="collections"
        @collectionChanged="onCollectionChanged"
        @menuButtonClicked="showDrawer = false"
      ></MainNav>
    </v-navigation-drawer>

    <v-app-bar app dark class="black" ref="AppBar">
      <v-btn
        ref="MainMenuButton"
        @click="showDrawer = !showDrawer"
        icon
        v-if="!showDrawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div class="d-flex align-center">
        <v-img
          alt="The Wicked Net Logo"
          class="shrink mr-2"
          contain
          src="@/assets/wicked-logo.png"
          transition="scale-transition"
          width="90"
        />
        <v-toolbar-title class="hidden-md-and-down"
          >Wicked Shout</v-toolbar-title
        >
      </div>

      <v-spacer></v-spacer>

      <v-autocomplete
        class="pt-5 mr-5"
        v-model="selectedCategory"
        :items="allCategories"
        label="Category"
        clearable
        @change="setCategoryFilter"
        @click:clear="clearCategoryFilter()"
      ></v-autocomplete>

      <v-text-field
        class="pt-5 mr-5"
        single-line
        clearable
        label="Filter"
        append-icon="mdi-magnify"
        v-model="filterString"
        v-debounce:300ms="setWordFilter"
        @click:clear="clearWordFilter()"
        ref="filterField"
      ></v-text-field>

      <VolumeSlider @volumeChanged="onVolumeChanged" />
      <v-btn icon @click="viewAsList = !viewAsList">
        <v-icon v-if="viewAsList">mdi-view-list</v-icon>
        <v-icon v-else>mdi-view-grid</v-icon>
      </v-btn>
      <FilterIcon
        :maxDuration="filters.maxDuration"
        :favouritesOnly="filters.favouritesOnly"
        :newOnly="filters.newOnly"
        @filtersChanged="onFiltersChanged"
      />
      <SortIcon
        @sortingChanged="onSortingChanged"
        :sortField="sorting.field"
        :reversed="sorting.reversed"
        :showFavFirst="sorting.showFavFirst"
      />
      <User :settingsService="settingsService" />
    </v-app-bar>

    <v-main>
      <v-system-bar v-show="selectedChannel !== ''" class="yellow darken-4">
        Connected to channel:
        <v-chip
          ref="channelChip"
          class="yellow darken-4"
          small
          close
          @click:close="resetChannel"
        >
          {{ selectedChannel }}
        </v-chip>
      </v-system-bar>
      <v-progress-linear color="yellow" v-model="progress"></v-progress-linear>
      <v-expansion-panels v-if="history.lastItem() !== null" ref="HistoryList">
        <v-expansion-panel>
          <v-expansion-panel-header
            >Last played:<Action
              :favourites="collections.favourites"
              :action="history.lastItem()"
              @collectionToggled="onCollectionToggled"
          /></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item-group>
                <v-list-item
                  v-for="item in reversed(history.everyThingButLast())"
                  :key="item.uuid"
                >
                  <v-list-item-content
                    ><Action
                      :favourites="collections.favourites"
                      :action="item"
                      @collectionToggled="onCollectionToggled"
                  /></v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- TODO replace 'newThreshold' and 'itemsPerRow' with settings -->
      <div class="router-view">
        <router-view
          :history="history"
          :filters="filters"
          :viewAsList="viewAsList"
          :collections="collections"
          :disabled="busy"
          :scrollerHeight="scrollerHeight"
          :jingles="jingles"
          :newThreshold="newThreshold"
          :itemsPerRow="itemsPerRow"
          :settingsService="settingsService"
          :channelService="channelService"
          :eventBus="eventBus"
          @jingleClicked="onJingleClicked"
          @collectionToggled="onCollectionToggled"
          @channelChanged="onChannelChanged"
          @newThresholdChanged="onNewThresholdChanged"
          @itemsPerRowChanged="onItemsPerRowChanged"
          @scrollHeightChangeRequest="this.updateScrollheight"
          @resetFilter="onResetFilter"
        ></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import User from "./components/User";
import Action from "./components/Action";
import FilterIcon from "./components/FilterIcon";
import SortIcon from "./components/SortIcon";
import MainNav from "./components/MainNav";
import VolumeSlider from "./components/VolumeSlider";
import { SocketService } from "@/socketService.js";
import { ChannelService } from "@/channelService.js";
import { CollectionService, CollectionFilter } from "@/collectionService.js";
import { SettingsService } from "@/settingsService.js";
import { JingleService } from "@/jingleService.js";
import { Migrator } from "@/migrator.js";
import { Player } from "@/player.js";
import { History } from "@/history.js";
import { DateTime } from "luxon";
import { reversed } from "@/collections.js";
import { JingleIterator, FilterName } from "@/jingleIterator.js";
import { ApiProxy } from "@/apiProxy.js";
import { Channel } from "@/models/channel.js";

export default {
  name: "App",

  props: {
    jingleService: {
      type: Object,
      default: JingleService.makeDefault,
    },
    collectionService: {
      type: Object,
      default: CollectionService.makeDefault,
    },
    socketService: {
      type: Object,
      default: SocketService.makeDefault,
    },
  },

  data: () => ({
    baseurl: process.env.VUE_APP_API,
    busy: false,
    channelService: new ChannelService(process.env.VUE_APP_API),
    settingsService: new SettingsService(Migrator.LATEST),
    eventBus: new Vue(),
    apiProxy: new ApiProxy(process.env.VUE_APP_API),
    collections: {},
    selectedCollection: "",
    jingles: JingleIterator.empty(),
    filters: {
      category: "",
      word: "",
      maxDuration: 50.0,
      favouritesOnly: false,
      newOnly: false,
      collection: new CollectionFilter(),
    },
    filterString: "",
    history: new History(),
    scrollerHeight: 50,
    selectedCategory: "",
    viewAsList: false,
    progress: 0,
    selectedChannel: "",
    showDrawer: false,
    sorting: {
      field: "name",
      reversed: false,
      showFavFirst: true,
    },
    newThreshold: 7,
    itemsPerRow: 6,
  }),

  computed: {
    hasHistory: function () {
      return this.history.hasContent();
    },

    allCategories: function () {
      return this.jingles.allCategories();
    },
  },

  methods: {
    resetChannel: function () {
      // TODO: Resetting the channel is application logic and should not be in a .vue file
      this.selectedChannel = "";
      this.settingsService.set("selectedChannel", "");
    },

    isNew: function (jingle) {
      // TODO: verify that `jingle.inserted` is a Date object instead of a string
      let inserted = jingle.inserted || "1970-01-01T00:00:00+0000";
      let time = DateTime.fromISO(inserted);
      let diff = Math.abs(time.diffNow(["days"]).days);
      return diff < 7 ? "new" : "";
    },

    onVolumeChanged: function (volume) {
      this.player.setVolume(volume / 100.0);
    },

    onFiltersChanged: function (newFilters) {
      this.jingles.setFilters(newFilters);
    },

    onResetFilter: function (filterName) {
      // TODO this.settingsService.set(FilterName.MAX_DURATION, filters.maxDuration);
      this.jingles.resetFilter(filterName);
    },

    onItemsPerRowChanged: function (newValue) {
      this.itemsPerRow = Number.parseInt(newValue, 10);
      this.settingsService.set("itemsPerRow", newValue);
    },

    onNewThresholdChanged: function (newValue) {
      this.newThreshold = newValue;
      this.settingsService.set("newThreshold", newValue);
      this.jingles.setNewThreshold(newValue);
    },

    onCollectionChanged: function (newValue) {
      let matcher = this.collectionService.matcher(newValue);
      // XXX remove? this.filters.collection = matcher;
      this.jingles.setFilter(FilterName.COLLECTION, matcher);
    },

    onCollectionToggled: async function (id, collectionName) {
      // TODO: Why does this assign to this.collections?
      this.collectionService.toggle(id, collectionName);
      this.collections = await this.collectionService.all();
    },

    onChannelChanged: function (newValue) {
      // TODO: Move the settings update into a watcher
      this.selectedChannel = newValue || "";
      this.settingsService.set("selectedChannel", newValue || "");
      this.apiProxy.submitNewChannel(new Channel(newValue));
    },

    onJingleClicked: function (res_str) {
      this.apiProxy.triggerJingle(
        this.settingsService.get("username"),
        this.selectedChannel,
        res_str
      );
    },

    getJingles: async function () {
      let output = await this.jingleService.getAll();
      this.jingles.setJingles(output);
    },

    updateScrollheight: function () {
      let fullHeight = window.innerHeight;
      let titleHeight = this.$refs.AppBar.$el.clientHeight;
      let filterHeight = 0;
      let historyHeight = 0;
      if (this.$refs.HistoryList) {
        historyHeight = this.$refs.HistoryList.$el.clientHeight;
      }

      // I was not yet able to determine where this offset comes from to
      // determine it dynamically. Adding a bit of "padding" like this, even if
      // it's hard-coded prevents the scroll-handle to move outside of the
      // screen.
      let bottomOffet = 25;
      if (this.$refs.FilterRow) {
        filterHeight = this.$refs.FilterRow.$el.clientHeight;
      }
      this.scrollerHeight =
        fullHeight - titleHeight - historyHeight - filterHeight - bottomOffet;
    },
    reversed: reversed,
    setWordFilter: function () {
      this.jingles.setFilter(FilterName.WORD, this.filterString);
    },
    clearWordFilter: function () {
      this.jingles.resetFilter(FilterName.WORD);
      this.filterString = "";
    },
    setCategoryFilter: function () {
      this.jingles.setFilter(
        FilterName.CATEGORY,
        this.selectedCategory?.toString() ?? ""
      );
    },
    clearCategoryFilter: function () {
      this.jingles.resetFilter(FilterName.CATEGORY);
      this.selectedCategory = "";
    },
    onSortingChanged: function (sortOptions) {
      this.sorting.field = sortOptions.field;
      this.sorting.reversed = sortOptions.reversed;
      this.sorting.showFavFirst = sortOptions.showFavFirst;
    },
    startProgressBar: function (duration) {
      if (this.progressIntervalId) {
        window.clearInterval(this.progressIntervalId);
      }
      let currentProgress = 0;
      let self = this;
      let interval = 50;
      this.progressIntervalId = window.setInterval(function () {
        currentProgress += interval / 1000;
        self.progress = (currentProgress / duration) * 100;
        if (self.progress >= 100) {
          window.clearInterval(self.progressIntervalId);
          self.progress = 0;
        }
      }, interval);
    },
    onPlayStarted: function (action) {
      this.history.put(action);
      let duration = action.jingle.duration;
      const self = this;
      if (duration > 1) {
        this.startProgressBar(duration);
      }
      if (duration > 0) {
        this.busy = true;
        window.setTimeout(() => {
          self.busy = false;
        }, duration * 1000);
      }
    },
  },

  watch: {
    selectedChannel: function (newValue) {
      this.player.selectedChannel = newValue;
    },
    hasHistory: function () {
      let self = this;
      Vue.nextTick(function () {
        self.updateScrollheight();
      });
    },
  },

  mounted() {
    this.getJingles();
    let filterField = this.$refs.filterField;
    this.updateScrollheight();
    window.addEventListener("resize", this.updateScrollheight, {
      passive: true,
    });
    window.addEventListener("keydown", function (e) {
      // TODO switch to event.code (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code)
      if (
        e.keyCode === 191 ||
        e.keyCode === 114 ||
        (e.ctrlKey && e.keyCode === 70)
      ) {
        e.preventDefault();
        e.stopPropagation();
        Vue.nextTick(function () {
          filterField.focus();
        });
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateScrollheight, {
      passive: true,
    });
  },

  async created() {
    this.jingleService.setProxy(this.apiProxy);
    this.collections = await this.collectionService.all();

    this.getJingles();

    this.jingles.setFilters(this.filters);
    this.jingles.setSorting(this.sorting);
    this.jingles.setFavouriteMatcher(
      new CollectionFilter("favourites", this.collections.favourites)
    );
    this.jingles.onFiltersChanged = (filters) => {
      Object.keys(filters).forEach((key) => {
        this.filters[key] = filters[key];
      });
    };
    this.jingles.setNewThreshold(this.settingsService.get("newThreshold", 7));

    let migrator = new Migrator();
    migrator.toLatest(localStorage);
    this.viewAsList = this.settingsService.get("viewAsList", false);
    this.newThreshold = this.settingsService.get("newThreshold", 7);
    this.selectedChannel = this.settingsService.get("selectedChannel", "");
    this.itemsPerRow = Number.parseInt(
      this.settingsService.get("itemsPerRow", 6),
      10
    );
    this.filters.maxDuration = this.settingsService.get(
      FilterName.MAX_DURATION,
      50
    );
    this.player = new Player(
      this.settingsService,
      this.baseurl,
      this.selectedChannel,
      this.onPlayStarted
    );
    // TODO: Only one socket should be needed in the following two lines!
    this.player.register(this.$options, this.$socket);
    this.apiProxy.setSocket(this.$socket);
    this.channelService.setProxy(this.apiProxy);
  },

  components: {
    Action,
    FilterIcon,
    MainNav,
    SortIcon,
    User,
    VolumeSlider,
  },
};
</script>

<style>
* {
  scrollbar-color: #555 black;
}

html {
  /** This override may no longer be needed in newer versions of vuetify
   *  See https://github.com/vuetifyjs/vuetify/issues/1197#issuecomment-721956645
   */
  overflow-y: hidden !important;
}
</style>
