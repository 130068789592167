import { DateTime } from "luxon";
export class Jingle {
  constructor(id, category, title, duration, inserted, hasMetadata) {
    this.id = id;
    this.category = category;
    this.title = title;
    this.duration = duration;
    this.inserted = inserted;
    this.hasMetadata = hasMetadata;
  }

  /**
   * Determine whether a jingle has been added in the last <n> days.
   *
   * @param {Number} threshold number of days in which a jingle is considered to
   *    be new
   */
  isNew(threshold) {
    let inserted =
      this.inserted || DateTime.fromISO("1970-01-01T00:00:00+0000");
    let time = DateTime.fromISO(inserted);
    let diff = Math.abs(time.diffNow(["days"]).days);
    return diff < threshold;
  }
}
