export class Action {

  constructor(uuid, action, channel, jingle, username, triggeredTime) {
    this.uuid = uuid
    this.action = action
    this.channel = channel
    this.jingle = jingle
    this.username = username
    this.triggeredTime = triggeredTime
  }

}