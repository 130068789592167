<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon dark v-bind="attrs" v-on="on" @click.stop="bubbleClick"
        >mdi-account</v-icon
      >
    </template>
    <span>{{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "UserAvatar",
  data: () => ({}),

  methods: {
    bubbleClick: function () {
      this.$emit("click", ...arguments);
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
