export class ChannelService {
  constructor(proxy) {
    this.proxy = proxy || null
  }

  setProxy(proxy) {
    this.proxy = proxy
  }

  /* istanbul ignore next */
  async search(channelName) {
    if (!this.proxy) {
      console.warn("No proxy available to fetch channels")
      return []
    }
    let channels = await this.proxy.fetchChannels(channelName)
    return channels
  }
}
