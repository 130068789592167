<template>
  <v-btn icon @click.stop="toggleFavourite"
    ><v-icon :color="favColor">{{ favIcon }}</v-icon></v-btn
  >
</template>

<script>
export default {
  name: "FavButton",

  methods: {
    toggleFavourite() {
      this.$emit("favouriteToggled", this.id);
    },
  },
  computed: {
    favColor: function () {
      return this.isFav ? "yellow darken-4" : "";
    },
    favIcon: function () {
      return this.isFav ? "mdi-star" : "mdi-star-outline";
    },
    isFav: function () {
      return this.favourites.includes(this.id);
    },
  },

  props: {
    favourites: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
