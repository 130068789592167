<template>
  <v-menu offset-y :close-on-content-click="false" :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-sort</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Sort Options</v-card-title>
      <v-card-text>
        <v-radio-group v-model="sortFieldLocal" @change="setSortToName">
          <v-radio key="name" label="Sort by name" value="name"></v-radio>
          <v-radio key="date" label="Sort by date" value="date"></v-radio>
          <v-radio key="length" label="Sort by length" value="length"></v-radio>
        </v-radio-group>
        <v-checkbox
          @change="toggleReversed"
          v-model="reversedLocal"
          label="Reverse Order"
        ></v-checkbox>

        <v-checkbox
          @change="toggleFavFirst"
          v-model="showFavFirstLocal"
          label="Show favourites first"
        ></v-checkbox>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="typescript">
export default {
  name: "SortIcon",
  data: () => ({
    sortFieldLocal: "name",
    reversedLocal: false,
    showFavFirstLocal: false,
  }),
  created () {
    this.sortFieldLocal = this.sortField
    this.reversedLocal = this.reversed
    this.showFavFirstLocal = this.showFavFirst
  },
  watch: {
      sortField: function (newValue) {
          this.sortFieldLocal = newValue
      },
      reversed: function (newValue) {
          this.reversedLocal = newValue
      },
      showFavFirst: function (newValue) {
          this.showFavFirstLocal = newValue
      },
  },
  props: {
      sortField: {
          type: String,
          default: "name",
      },
      reversed: {
        type: Boolean,
        default: false,
      },
      showFavFirst: {
        type: Boolean,
        default: true,
      }
  },
  methods: {
    toggleReversed: function () {
      this.$emit("sortingChanged", {"field": this.sortFieldLocal, reversed: this.reversedLocal, showFavFirst: this.showFavFirstLocal})
    },
    toggleFavFirst: function () {
      this.$emit("sortingChanged", {"field": this.sortFieldLocal, reversed: this.reversedLocal, showFavFirst: this.showFavFirstLocal})
    },
    setSortToName: function(field){
      this.$emit("sortingChanged", {"field": field, reversed: this.reversedLocal, showFavFirst: this.showFavFirstLocal})
    }
  }
};
</script>
