/**
 * Return a reversed copy of an array.
 *
 * @param {*} arr The array to be reversed
 * @returns The reversed copy
 */
export function reversed(arr) {
  return arr.slice().reverse();
}

/**
 * Convert an iterator into a array of arrays where each item of the top-level
 * array has *chunkSize* items.
 *
 * @param {Iterator} iterable The iterator to be chunked
 * @param {Number} chunkSize The size of each chunk
 * @returns The chunked array
 */
export function chunkify(iterable, chunkSize) {
  let output = [];
  let currentChunk = [];
  for (const item of iterable) {
    if (currentChunk.length === chunkSize) {
      output.push(currentChunk);
      currentChunk = [];
    }
    currentChunk.push(item);
  }

  if (currentChunk.length > 0) {
    output.push(currentChunk);
  }
  return output;
}
