<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2">Change Username</v-card-title>

        <v-divider></v-divider>
        <v-text-field v-model="txtUsername" label="New Username" class="ma-4">{{
          txtUsername
        }}</v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            @click="
              setUsername(txtUsername);
              dialog = false;
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserAvatar @click.stop="dialog = true" :name="txtUsername" />
  </div>
</template>

<script>
import rug from "random-username-generator";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "User",

  props: {
    settingsService: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    txtUsername: "",
    dialog: false,
  }),

  mounted() {
    let currentName = this.settingsService.get("username");
    if (currentName) {
      this.txtUsername = currentName;
    } else {
      this.txtUsername = rug.generate();
      this.settingsService.set("username", this.txtUsername);
    }
  },

  methods: {
    setUsername: function (newUsername) {
      this.txtUsername = newUsername;
      this.settingsService.set("username", this.txtUsername);
    },
  },

  watch: {
    username(newUsername) {
      this.settingsService.set("username", newUsername);
    },
  },

  components: {
    UserAvatar,
  },
};
</script>
