<template>
  <v-list>
    <v-list-item>
      <v-list-item-icon>
        <v-icon @click="closeNavBar">mdi-menu</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="title"> Wicked Shout </v-list-item-title>
        <v-list-item-subtitle class="title">
          <small>v{{ app_version }}</small>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item to="/">
      <v-list-item-content>
        <v-list-item-title class="title">Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">Collections</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item @click="selectCollection('all')">
      <v-list-item-content>All</v-list-item-content>
    </v-list-item>
    <v-list-item
      v-for="collection in Object.keys(collections)"
      @click="selectCollection(collection)"
      :key="collection"
    >
      <v-list-item-content>{{ collection }}</v-list-item-content>
    </v-list-item>

    <v-list-item to="/settings">
      <v-list-item-icon>
        <v-icon>mdi-cog</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="title">Settings</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item to="/changelog">
      <v-list-item-icon>
        <v-icon>mdi-list-status</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="title">Changelog</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "MainNav",
  data: () => ({}),
  methods: {
    selectCollection: function (collectionName) {
      this.$emit(
        "collectionChanged",
        collectionName === "all" ? "" : collectionName
      );
    },
    closeNavBar: function () {
      this.$emit("menuButtonClicked");
    },
  },
  computed: {
    app_version: function () {
      return process.env.VUE_APP_VERSION;
    },
  },
  created() {},
  watch: {},
  props: {
    collections: {
      type: Object,
      required: true,
    },
  },
  components: {},
};
</script>
