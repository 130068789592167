<template>
  <span>
    <FavButton
      :favourites="favourites"
      :id="action.jingle.resource_str"
      @favouriteToggled="onFavouriteToggled"
    />
    <strong>{{ action.jingle.resource_str }}</strong> by
    <em>{{ action.shouter }}</em
    >&nbsp;<span>{{ displayTime }}</span>
  </span>
</template>

<script>
import FavButton from "@/components/FavButton";
import { DateTime } from "luxon";

export default {
  name: "Action",
  data: () => ({
    displayTime: "",
  }),

  methods: {
    /* istanbul ignore next */
    getTimeAndDiff() {
      let time = DateTime.fromISO(this.action.triggered);
      let now = DateTime.utc();
      let diff = now.diff(time).milliseconds;
      return { time: time.setLocale("en"), diff: diff };
    },
    formatTime() {
      let { time, diff } = this.getTimeAndDiff();
      if (diff < 1000) {
        return "";
      } else {
        return time.toRelative();
      }
    },
    updateTimeDisplay() {
      let { time, diff } = this.getTimeAndDiff();
      if (diff > 60 * 60 * 1000) {
        window.clearInterval(this.timeoutId);
        this.timeoutId = null;
        this.displayTime = time.toLocaleString(DateTime.DATETIME_FULL);
      } else {
        this.displayTime = this.formatTime();
      }
    },
    startTicking() {
      let self = this;
      self.displayTime = self.formatTime();
      if (this.timeoutId) {
        window.clearInterval(this.timeoutId);
      }
      this.timeoutId = window.setInterval(this.updateTimeDisplay, 10000);
    },
    onFavouriteToggled: function () {
      this.$emit(
        "collectionToggled",
        this.action.jingle.resource_str,
        "favourites"
      );
    },
  },

  mounted() {
    this.startTicking();
  },

  watch: {
    action: function () {
      /* istanbul ignore next */
      this.startTicking();
    },
  },

  beforeDestroy() {
    if (this.timeoutId) {
      window.clearInterval(this.timeoutId);
    }
  },

  props: {
    action: {
      type: Object,
      required: true,
    },
    favourites: {
      type: Array,
      required: true,
    },
  },
  components: {
    FavButton,
  },
};
</script>
