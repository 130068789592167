/**
 * The history keeps track of items that have been played in the past
 *
 * It also provides a couple of convenience functions.
 */
export class History {
  constructor() {
    this.items = [];
  }

  /**
   * @returns The latest item
   */
  lastItem() {
    if (this.items && this.items.length === 0) {
      return null;
    }
    return this.items[this.items.length - 1];
  }

  /**
   * @returns True if the history contains any items
   */
  hasContent() {
    return this.items.length > 0;
  }

  /**
   * @returns All items of the history with the exception of the latest item
   */
  everyThingButLast() {
    return this.items.slice(0, -1);
  }

  /**
   * Adds a new item to the history
   *
   * @param {*} item The item to add
   */
  put(item) {
    this.items.push(item);
  }
}
