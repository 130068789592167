export class SettingsService {
  constructor(latestVersion) {
    this.latest = latestVersion;
  }

  get(settingName, fallback) {
    let output = localStorage.getItem(settingName);
    if (output === undefined || output === null) {
      return fallback;
    }
    return JSON.parse(output);
  }

  set(settingName, value) {
    let modelVersion = localStorage.getItem("dataModelVersion", null);
    if (modelVersion === null) {
      localStorage.setItem("dataModelVersion", this.latest);
    }
    return localStorage.setItem(settingName, JSON.stringify(value));
  }
}
