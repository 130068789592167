import { CollectionFilter } from "./collectionService";

export const FilterName = Object.freeze({
  MAX_DURATION: "maxDuration",
  WORD: "word",
  CATEGORY: "category",
  NEWONLY: "newOnly",
  FAVOURITESONLY: "favouritesOnly",
  COLLECTION: "collection",
});

/**
 * This class encapsulates the logic for filtering and sorting jingles.
 *
 * It can behave like an interator and can be looped over.
 */
export class JingleIterator {
  constructor(
    data,
    filters,
    sorting,
    favouriteMatcher,
    onFiltersChanged,
    newThreshold
  ) {
    this.__data = data;
    this.filters = filters;
    this.sorting = sorting;
    this.favouriteMatcher = favouriteMatcher;
    this.onFiltersChanged = onFiltersChanged ?? function () {};
    this.newThreshold = newThreshold ?? 7;
  }

  /**
   * @returns An empty view
   */
  static empty() {
    return new JingleIterator(
      [],
      {},
      {},
      () => false,
      function () {},
      7
    );
  }

  setNewThreshold(newThreshold) {
    this.newThreshold = newThreshold ?? 7;
  }

  /**
   * Set the wrapped data to a new value
   *
   * @param {Array} data The new collection of jingles
   */
  setJingles(data) {
    this.__data = data;
  }

  /**
   * Update the filters
   *
   * @param {Object} filters New filter definition
   */
  setFilters(filters) {
    Object.keys(filters).forEach((item) => {
      this.setFilter(item, filters[item]);
    });
  }

  setFilter(filterName, value) {
    switch (filterName) {
      case FilterName.MAX_DURATION:
        this.filters.maxDuration = value;
        break;
      case FilterName.WORD:
        this.filters.word = value;
        break;
      case FilterName.CATEGORY:
        this.filters.category = value;
        break;
      case FilterName.NEWONLY:
        this.filters.newOnly = value;
        break;
      case FilterName.FAVOURITESONLY:
        this.filters.favouritesOnly = value;
        break;
      case FilterName.COLLECTION:
        this.filters.collection = value;
        break;
      default:
        throw new Error(`Unknown filter: ${filterName}`);
    }
    this.onFiltersChanged(this.filters);
  }

  /**
   * Reset a filter to its initial value
   *
   * @param {String} filterName The name of the filter to reset
   */
  resetFilter(filterName) {
    switch (filterName) {
      case FilterName.MAX_DURATION:
        this.filters.maxDuration = 0.0;
        break;
      case FilterName.WORD:
        this.filters.word = "";
        break;
      case FilterName.CATEGORY:
        this.filters.category = "";
        break;
      case FilterName.NEWONLY:
        this.filters.newOnly = false;
        break;
      case FilterName.FAVOURITESONLY:
        this.filters.favouritesOnly = false;
        break;
      case FilterName.COLLECTION:
        this.filters.collection = new CollectionFilter();
        break;
      default:
        throw new Error(`Unknown filter: ${filterName}`);
    }
    this.onFiltersChanged(this.filters);
  }

  /**
   * Update the sorting definition
   *
   * @param {Object} sorting New sortingdefinition
   */
  setSorting(sorting) {
    this.sorting = sorting;
  }

  /**
   * Set a new filter which matches favourites
   *
   * @param {CollectionFilter} filter A filter for favourites
   */
  setFavouriteMatcher(filter) {
    this.favouriteMatcher = filter;
  }

  /**
   * @param {Object} jingle The jingle to check
   * @returns Whether the jingle is included in the resulting list or not
   */
  isIncluded(jingle) {
    let collectionMatcher = this.filters.collection ?? new CollectionFilter();
    return (
      (this.filters.word === "" ||
        jingle.title
          .toString()
          .toLowerCase()
          .includes(this.filters.word.toLowerCase()) ||
        jingle.category
          .toString()
          .toLowerCase()
          .includes(this.filters.word.toLowerCase())) &&
      (this.filters.category === "" ||
        jingle.category.toString() === this.filters.category) &&
      (this.filters.maxDuration === 0.0 ||
        jingle.duration <= this.filters.maxDuration / 10) &&
      (this.filters.favouritesOnly === false ||
        this.favouriteMatcher.contains(jingle)) &&
      (this.filters.newOnly === false || jingle.isNew(this.newThreshold)) &&
      collectionMatcher.contains(jingle)
    );
  }

  /**
   * @returns A sorted (unfiltered) list of jingles
   */
  sorted() {
    // favourites are stored separately from the jingles themselves (they
    // don't have a favourite flag. So we add this here for sorting.
    const sortingData = this.__data.map((item) => {
      return [this.favouriteMatcher.contains(item) ? 0 : 1, item];
    });
    // using two arrays in the comparison operator ensures proper sorting of
    // the items
    sortingData.sort((a, b) => {
      let comp = [];
      if (this.sorting.showFavFirst) {
        if (this.sorting.reversed) {
          comp.push(b[0] - a[0]);
        } else {
          comp.push(a[0] - b[0]);
        }
      }
      if (this.sorting.field === "date") {
        comp.push(a[1].inserted - b[1].inserted);
      } else if (this.sorting.field === "length") {
        comp.push(a[1].duration - b[1].duration);
      }
      comp.push(a[1].category.localeCompare(b[1].category));
      comp.push(a[1].title.localeCompare(b[1].title));
      let output = comp[0];
      comp.slice(1).forEach((item) => {
        output = output || item;
      });
      return this.sorting.reversed ? output * -1 : output;
    });

    // After having the favourite flag added for sorting we need to remove it
    // again.
    let output = sortingData.map((item) => {
      return item[1];
    });
    return output;
  }

  allCategories() {
    let output = [];
    for (const item of this) {
      if (output.includes(item.category)) {
        continue;
      }
      output.push(item.category);
    }
    return output;
  }

  /**
   * Iterate of the items applying both sorting and filtering
   */
  *[Symbol.iterator]() {
    for (const item of this.sorted()) {
      if (this.isIncluded(item)) {
        yield item;
      }
    }
  }
}
