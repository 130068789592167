import { Jingle } from "@/models/jingle.js";
import { Action } from "@/models/action.js";
import { Channel } from "@/models/channel.js";
import { DateTime } from "luxon";

export class ApiProxy {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.mapper = new TypeMapper();
    this.socket = null;
  }

  setSocket(socket) {
    this.socket = socket;
  }

  async get(path, acceptTypes) {
    console.debug(`Fetching ${path} using ${acceptTypes}`);
    let effective_types = "";
    if (acceptTypes && acceptTypes.length) {
      acceptTypes = acceptTypes.concat(["application/json"]);
      let quality_shift = 1.0 / acceptTypes.length;
      let quality = 1.0;
      let qualified_accepts = [];
      acceptTypes.forEach((item) => {
        qualified_accepts.push(`${item}; q=${quality}`);
        quality -= quality_shift;
      });
      effective_types = qualified_accepts.join(", ");
    } else {
      effective_types = "application/json";
    }

    let response = await fetch(`${this.baseUrl}${path}`, {
      headers: {
        Accept: effective_types,
      },
    });
    if (!response.ok) {
      console.error(response);
    }
    let responseType = response.headers.get("Content-Type");
    let naiveType = responseType.split(";")[0];
    if (!acceptTypes.includes(naiveType)) {
      throw new Error(
        `Expected to get any of ${acceptTypes} but got ${responseType}`
      );
    }
    return this.mapper.convert(path, response);
  }

  parseMessage(rawMessage) {
    let action = JSON.parse(rawMessage);
    return this.mapper.convertAction(action);
  }

  registerUser(username) {
    if (!this.socket) {
      console.error("Socket not initialised yet");
      return;
    }
    this.socket.sendObj({
      action: "REGISTER",
      shouter: username,
    });
  }

  triggerJingle(username, channel, res_str) {
    if (!this.socket) {
      console.error("Socket not initialised yet");
      return;
    }
    let obj = {
      action: "PLAY",
      shouter: username,
      channel: channel,
      jingle: {
        resource_str: res_str,
      },
    };
    this.socket.sendObj(obj);
  }

  async fetchChannels(channelName) {
    let filter = channelName || "";
    let channels = await this.get(`/channels?query=${filter}`, [
      "application/channel-list+json; v=1",
    ]);
    return channels;
  }

  async submitNewChannel(channel) {
    let contentType = "application/channel+json; v=1";
    fetch(`${this.baseUrl}/channel`, {
      method: "POST",
      headers: {
        "Content-Type": contentType,
      },
      body: this.mapper.encodeChannel(channel, contentType),
    });
  }
}

class TypeMapper {
  async convert(path, response) {
    let data = await response.json();
    if (path === "/jingle") {
      return this.convertJingleList(data);
    } else if (path.match(/^\/channels(\?.*)?$/)) {
      return this.convertChannelList(data);
    }
    throw new Error("Unable to process response");
  }

  convertJingleList(data) {
    let output = [];
    data.jingles.forEach((item) => {
      output.push(
        new Jingle(
          item.resource_str,
          item.movie,
          item.title,
          item.duration,
          DateTime.fromISO(item.inserted),
          item.has_metadata
        )
      );
    });
    return output;
  }

  convertChannelList(data) {
    let output = [];
    data.channels.forEach((item) => {
      output.push(new Channel(item.name));
    });
    return output;
  }

  encodeChannel(channel, contentType) {
    if (contentType !== "application/channel+json; v=1") {
      throw new Error(`Unsupported content-type for channels: ${contentType}`);
    }
    return JSON.stringify({
      name: channel.name,
    });
  }

  convertAction(data) {
    let jingle = null;
    if (data.jingle) {
      jingle = new Jingle(
        data.jingle.resource_str,
        null,
        null,
        data.jingle.duration,
        null,
        null
      );
    }
    let output = new Action(
      data.uuid,
      data.action,
      data.channel,
      jingle,
      data.shouter,
      DateTime.fromISO(data.triggered)
    );
    return output;
  }
}
