function dropMp3Suffix(items) {
  let output = [];
  items.forEach((item) => {
    if (item.endsWith(".mp3")) {
      item = item.slice(0, -4);
    }
    if (!output.includes(item)) {
      output.push(item);
    }
  });
  return output;
}

function to1(data) {
  // TODO don't modify *data* in-place
  data["dataModelVersion"] = 1;
  let collections = JSON.parse(data["collections"] || "{}");
  Object.keys(collections).forEach((key) => {
    collections[key] = dropMp3Suffix(collections[key]);
  });
  data["collections"] = JSON.stringify(collections);
  return data;
}

function to2(data) {
  // TODO don't modify *data* in-place
  data["dataModelVersion"] = 2;
  let userName = data["username"];
  data["username"] = JSON.stringify(userName);
  return data;
}

export class Migrator {
  static LATEST = 2;
  constructor() {
    this.migrations = [to1, to2];
  }

  toLatest(data) {
    let currentVersion = data["dataModelVersion"] || 0;
    this.migrations.slice(currentVersion).forEach((item) => {
      data = item(data);
    });
    return data;
  }
}
