import { Howl } from "howler";
export class Player {
  constructor(settingsService, baseUrl, selectedChannel, playCallback) {
    this.settingsService = settingsService;
    this.baseUrl = baseUrl;
    this.selectedChannel = selectedChannel || "";
    this.playCallback = playCallback;
    this.currentPlayer = null;
    this.volume = 1.0;
  }

  /* istanbul ignore next */
  register(options, socket) {
    let self = this;
    // Setup Websocket to register on connect
    if (!options.sockets) {
      return;
    }
    options.sockets.onopen = () => {
      self.registerUser(socket);
    };

    // Setup Websocket to recieve play events
    options.sockets.onmessage = (msg) => {
      self.handleMessage(msg);
    };
  }

  setVolume(newVolume) {
    if (this.currentPlayer) {
      this.currentPlayer.volume(newVolume);
    }
    this.volume = newVolume;
  }

  handleMessage(msg) {
    let action = JSON.parse(msg.data);
    if (action.action === "play") {
      this.playJingle(action);
    } else {
      console.log(`Unhandled action: ${action.action}`);
    }
  }

  registerUser(socket) {
    socket.sendObj({
      action: "REGISTER",
      shouter: this.settingsService.get("userName", "<unknown user>"),
    });
  }

  /* istanbul ignore next */
  _playJingleInternal(url) {
    this.currentPlayer = new Howl({
      src: [url],
      autoplay: true,
      volume: this.volume,
      format: "mp3",
      onplay: function () {
        console.debug("Start playing " + url);
      },
      onend: function () {
        console.debug("Finished " + url);
      },
    });
  }

  playJingle(action) {
    if (!action.jingle) {
      return;
    }
    let targetChannel = action.channel || "";
    if (targetChannel !== this.selectedChannel) {
      return;
    }
    this._playJingleInternal(
      this.baseUrl + "/jingle/" + action.jingle.resource_str
    );
    if (this.playCallback) {
      this.playCallback(action);
    }
  }
}
