import Vue from "vue";

export class SocketService {
  constructor(vue) {
    this.vue = vue;
  }

  static makeDefault() {
    let vue = new Vue();
    return new SocketService(vue);
  }

  send(obj) {
    /* istanbul ignore next */

    this.vue.$socket.sendObj(obj);
  }
}
