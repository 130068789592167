const History = () => import("./views/History.vue");
const JinglesView = () => import("./views/JinglesView.vue");
const Settings = () => import("./views/Settings.vue");
const Changelog = () => import("./views/Changelog.vue");

let routes = [
  { path: "/", component: JinglesView },
  { path: "/history", component: History },
  { path: "/settings", component: Settings },
  { path: "/changelog", component: Changelog },
];

export { routes };
