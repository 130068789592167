<template>
  <v-menu offset-y :close-on-content-click="false" :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" ref="filterIcon">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Filters</v-card-title>
      <v-card-text>
        <v-slider
          :hint="maxDurationHint"
          persistent-hint
          prepend-icon="mdi-clock"
          max="600"
          min="0"
          v-model="maxDurationLocal"
          @end="updateMaxDuration"
        ></v-slider>
        <v-checkbox
          @click="updateFavouritesOnly"
          v-model="favouritesOnlyLocal"
          label="Favourites Only"
        ></v-checkbox>
        <v-checkbox
          @click="updateNewOnly"
          v-model="newOnlyLocal"
          label="New Jingles Only"
        ></v-checkbox>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="typescript">
import { FilterName } from "@/jingleIterator.js";
export default {
  name: "FilterIcon",
  data: () => ({
      maxDurationLocal: 50.0,
      favouritesOnlyLocal: false,
      newOnlyLocal: false,
  }),
  created () {
      this.maxDurationLocal = this.maxDuration
      this.favouritesOnlyLocal = this.favouritesOnly
      this.newOnlyLocal = this.newOnly
  },
  watch: {
      favouritesOnly: function (newValue) {
          this.favouritesOnlyLocal = newValue
      },
      newOnly: function (newValue) {
          this.newOnlyLocal = newValue
      },
      maxDuration: function (newValue) {
          this.maxDurationLocal = newValue
      }
  },
  props: {
      maxDuration: {
          type: Number,
          default: 50.0
      },
      favouritesOnly: {
        type: Boolean,
        default: false,
      },
      newOnly: {
        type: Boolean,
        default: false,
      }
  },
  computed: {
    maxDurationHint: function () {
      return `Max. sound-length ${this.maxDurationLocal / 10}s`
    }
  },
  methods: {
    updateFavouritesOnly () {
      this.emitNewFilters()
    },
    updateNewOnly () {
      this.emitNewFilters()
    },
    updateMaxDuration (newValue) {
      this.maxDurationLocal = newValue
      this.emitNewFilters()
    },
    emitNewFilters() {
      let newFilters = {};
      newFilters[FilterName.MAX_DURATION] = this.maxDurationLocal;
      newFilters[FilterName.FAVOURITESONLY] = this.favouritesOnlyLocal;
      newFilters[FilterName.NEWONLY] = this.newOnlyLocal;
      this.$emit("filtersChanged", newFilters);
    },
  },
};
</script>
