export class JingleService {
  constructor(apiProxy) {
    this.proxy = apiProxy;
  }

  static makeDefault() {
    return new JingleService(null);
  }

  setProxy(proxy) {
    this.proxy = proxy;
  }

  /* istanbul ignore next */
  async getAll() {
    if (!this.proxy) {
      return [];
    }
    let response = await this.proxy.get("/jingle", ["application/json"]);
    return response;
  }
}
