export class CollectionFilter {
  constructor(name = "", collection = []) {
    this.collection = collection;
    this.name = name;
  }

  contains(jingle) {
    if (this.collection.length === 0) {
      return true;
    }
    let output = this.collection.includes(jingle.id);
    return output;
  }
}

export class CollectionService {
  constructor(collections) {
    this.collections = collections || {};
  }

  /* istanbul ignore next */
  static makeDefault() {
    const data = localStorage.getItem("collections");
    let collections;
    if (data) {
      collections = JSON.parse(data);
    } else {
      collections = {};
    }
    collections.favourites = collections.favourites || [];
    return new CollectionService(collections);
  }

  /* istanbul ignore next */
  save() {
    localStorage.setItem("collections", JSON.stringify(this.collections));
  }

  async all() {
    return this.collections;
  }

  toggle(id, name) {
    if (this.collections[name] === undefined) {
      this.collections[name] = [id];
    } else if (this.isContainedIn(id, name)) {
      const index = this.collections[name].indexOf(id);
      this.collections[name].splice(index, 1);
    } else {
      this.collections[name].push(id);
    }
    this.save();
  }

  isContainedIn(id, name) {
    let collection = this.collections[name] ?? [];
    return collection.includes(id);
  }

  matcher(name) {
    if (!Object.keys(this.collections).includes(name)) {
      return new CollectionFilter("");
    }
    return new CollectionFilter(name, this.collections[name]);
  }
}
