<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon v-show="volume >= 66">mdi-volume-high</v-icon>
        <v-icon v-show="volume >= 33 && volume < 66">mdi-volume-medium</v-icon>
        <v-icon v-show="volume >= 1 && volume < 33">mdi-volume-low</v-icon>
        <v-icon v-show="volume < 1">mdi-volume-off</v-icon>
      </v-btn>
    </template>
    <v-card id="VolumeSlider" class="pb-4 pt-4">
      <v-slider v-model="volume" min="0" max="100" vertical></v-slider>
    </v-card>
  </v-menu>
</template>

<script lang="typescript">
export default {
  name: "VolumeSlider",
  data: () => ({
    volume: 100.0,
  }),
  watch: {
    volume: function (volume) {
      this.$emit("volumeChanged", volume)
    }
  },
};
</script>

<style scoped>
#VolumeSlider {
  overflow: hidden;
  text-align: center;
}
</style>
